/**
 * build a Directus filter payload on translation fields
 * used for search
 * @param query search keyword(s)
 * @param fields fields to search in translations
 * @returns
 */
export const useTranslationSearchPayload = (
  query: string | null,
  fields: string[]
) => {
  return (
    (query && {
      translations: {
        _or: fields.map((field: string) => {
          return {
            [field]: {
              _contains: query.toLowerCase()
            }
          }
        })
      }
    }) ||
    {}
  )
}
